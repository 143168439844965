<script setup lang="ts">
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
import resourceHelper from '@/Utils/resourceHelper';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';

const props = defineProps<{
    limitNumberOfUploads: number,
    existedUploads: number,
    newUploads: number,
    totalUploads: number,
    uploadParentItemType: UploadParentItemType
}>();

const emit = defineEmits<{ 'close' }>();

function onModalClose() {
  emit('close');
}

function calculateAdditionalParentItems(totalCount: number, limit: number) {
  if (limit === 0) {
    return 0;
  }
  const result = Math.ceil(totalCount / limit);
  return result - 1;
}

const exceededBy = props.totalUploads - props.limitNumberOfUploads;

const maximumAllowedUploads = resourceHelper.getString('MaximumAllowedUploads', { limitNumberOfUploads: props.limitNumberOfUploads });
const existedUploads = resourceHelper.getString('ExistedUploads', { existedUploads: props.existedUploads });
const newUploads = resourceHelper.getString('NewUploads', { newUploads: props.newUploads });
const totalUploadsExceededBy = resourceHelper.getString('TotalUploads', { totalUploads: exceededBy });
const additionalRequestNumber = calculateAdditionalParentItems(props.totalUploads, props.limitNumberOfUploads);
const uploadLimitNote = resourceHelper.getString(props.uploadParentItemType === UploadParentItemType.partnerRequest ||
    props.uploadParentItemType === UploadParentItemType.businessRequest ?
    'UploadLimitNote' : 'UploadsFolderUploadLimitNote', { additionalRequestNumber });
</script>

<template>
  <ModalDialog
    :title="$localize('UploadLimitTitle')"
    :is-danger="true"
    @close="onModalClose"
  >
    <template #default>
      <div>
        {{ maximumAllowedUploads }}
      </div>
      <div>
        {{ existedUploads }}
      </div>
      <div>
        {{ newUploads }}
      </div>
      <div>
        {{ totalUploadsExceededBy }}
      </div>
      <div v-if="additionalRequestNumber > 0">
        <br>
        {{ uploadLimitNote }}
      </div>
    </template>
    <template #footer>
      <button
        ref="closeBtn"
        class="btn btn-default"
        @click="onModalClose"
      >
        {{ $localize('Cancel') }}
      </button>
    </template>
  </ModalDialog>
</template>
